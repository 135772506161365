import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { GridContainer, GridItem } from '@entur/grid';
import { UnorderedList, ListItem, StrongText, Paragraph, Heading3 } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
export const query = graphql`
query enturFiles {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/en---tur"}, extension: {eq: "png"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <h2 {...{
      "id": "prinsipper-for-bevegelse"
    }}>{`Prinsipper for bevegelse`}</h2>
    <p>{`Typografi er et av de bærende grafiske elementene i identiteten.
Gjennom bruk av ulike vekter gjør vi innholdet til helten og skaper et lekent og variert uttrykk.
Oppsettet tilfører bevegelse og dynamikk til identiteten, og understøtter mangfoldigheten og kontrastene i alle de forskjellige turene Entur tilrettelegger for.`}</p>
    <GridContainer spacing="extraLarge" mdxType="GridContainer">
  <GridItem small={12} medium={8} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "Logo_aapne").childImageSharp.fluid} alt="Viser hvordan ENTUR kan skrives på tvers av tre akser: horisontal, vertikalt eller diagonalt" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={12} medium={4} mdxType="GridItem">
    <div>
      EN og TUR kan åpne seg på tre ulike måter for å vise innhold, som
      forholder seg til formatet det er satt opp på. EN og TUR beveger seg på én
      av tre måter:
    </div>
    <UnorderedList mdxType="UnorderedList">
      <ListItem mdxType="ListItem">Langs horisontalen av formatet.</ListItem>
      <ListItem mdxType="ListItem">Langs vertikalen av formatet.</ListItem>
      <ListItem mdxType="ListItem">
        Langs diagonalen av formatet, men kun fra øvre venstre til nedre høyre
        hjørne.
      </ListItem>
    </UnorderedList>
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "bruk-med-typografi"
    }}>{`Bruk med Typografi`}</h2>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "Typo_turer").childImageSharp.fluid} alt="Typografiske eksempler hvor et ord er lagt inn mellom EN og Tur som en sammenslått Entur-logo. Eksempel: En God Tur" preset="full-width-image" mdxType="ImageDisplay" />
    <h3 {...{
      "id": "unngå-"
    }}>{`Unngå …`}</h3>
  <DoDontGroup mdxType="DoDontGroup">
    <DoDontCard contrast variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Typo_dont_1").childImageSharp.fluid} alt="Sammenslått Entur logo med grønn og grå tekst imellom" noPadding title="… at innholdet står i annet enn korall" mdxType="DoDontCard">
      <Paragraph mdxType="Paragraph">
        Innholdet mellom EN og TUR skal alltid stå i korallrød.
      </Paragraph>
    </DoDontCard>
    <DoDontCard contrast variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Typo_dont_2").childImageSharp.fluid} alt="Sammenslått Entur-logo med liten tekst imellom " noPadding title="… for liten tekst" mdxType="DoDontCard">
      <Paragraph mdxType="Paragraph">
        Innholdet mellom EN og TUR skal alltid stå i korallrød.
      </Paragraph>
    </DoDontCard>
    <DoDontCard contrast variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Typo_dont_3").childImageSharp.fluid} alt="Sammenslått Entur-logo med små bokstaver imellom" noPadding title="… små bokstaver" mdxType="DoDontCard">
      <Paragraph mdxType="Paragraph">Innholdet står alltid i versaler.</Paragraph>
    </DoDontCard>
    <DoDontCard contrast variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Typo_dont_4").childImageSharp.fluid} alt="Sammenslått Entur-logo som ikke følger en av de tre aksene nevnt over" noPadding title="… tilfeldig plassering av EN og TUR" mdxType="DoDontCard">
      <Paragraph mdxType="Paragraph">
        EN og TUR forholder seg alltid til de tre retningene som beskrevet over.
      </Paragraph>
    </DoDontCard>
    <DoDontCard contrast variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Typo_dont_5").childImageSharp.fluid} alt="Sammenslått Entur-logo hvor de forskjellige elementene varierer i størrelse" noPadding title="… ulik størrelse på EN og TUR" mdxType="DoDontCard">
      <Paragraph mdxType="Paragraph"> EN og TUR står alltid i samme størrelse.</Paragraph>
    </DoDontCard>
    <DoDontCard contrast variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Typo_dont_6").childImageSharp.fluid} alt="Sammenslått Entur-logo i reversert rekkefølge på vertikalen" noPadding title="… plassering av EN og TUR bakvendt" mdxType="DoDontCard">
      <Paragraph mdxType="Paragraph"> Når EN og TUR plasseres langs formatets diagonaler, skal EN alltid stå øverst til venstre. </Paragraph>
    </DoDontCard>
  </DoDontGroup>
    <h2 {...{
      "id": "bruk-med-foto"
    }}>{`Bruk med foto`}</h2>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "EN---TUR_foto-1").childImageSharp.fluid} alt="Entur-logoen over fjordlandskap" preset="full-width-image" mdxType="ImageDisplay" />
    <GridContainer spacing="extraLarge" mdxType="GridContainer">
  <GridItem small={6} medium={4} mdxType="GridItem">
    <Heading3 mdxType="Heading3">Horisontal</Heading3>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "EN---TUR_foto-2").childImageSharp.fluid} alt="Entur-logoen spredd horisontalt på rammen av et bilde" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <Heading3 mdxType="Heading3">Vertikal</Heading3>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "EN---TUR_foto-3").childImageSharp.fluid} alt="Entur-logoen spredd vertikalt på rammen av et bilde" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <Heading3 mdxType="Heading3">Diagonal</Heading3>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "EN---TUR_foto-4").childImageSharp.fluid} alt="Entur-logoen spredd diagonalt på rammen av et bilde" preset="full-width-image" mdxType="ImageDisplay" />    
  </GridItem>
    </GridContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      